<template>
  <div>
    <h1 :class="clasetext">{{ pregunta }}</h1>
    <div>
      <button :class="claseButton1" style="margin-right: 10px;" @click="responder(true)">{{respuesta_1}}</button>
      <button :class="claseButton2" style="margin-right: 10px;" @click="responder(false)">{{respuesta_2}}</button>
    </div>
  </div>
</template>
<style>
.btn-trivia {
  width: 90px;
  margin: 10px; /* Agrega un margen a la derecha */
}
</style>
<script>
export default {
  name: 'PreguntaTrivia',
  props: {
    clasetext: {
      type: String,
      required: true,
    },
    c13: {
      type: Boolean,
      required: true,
    },
    got: {
      type: Boolean,
      required: true,
    },
    bunkers: {
      type: Boolean,
      required: true,
    },
    mne: {
      type: Boolean,
      required: true,
    },
    festivallascondes: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      claseButton1: null,
      claseButton2: null,
      pregunta: "¿Quién es el conductor de Tierra Brava?",
      respuesta_1: "Sergio Lagos",
      respuesta_2: "Martín Carcamo",
    };
  },
  methods: {
    responder(respuesta) {
      if (respuesta) {
        // Lógica para respuesta "Sí"
        this.$emit('changeValue', {
          triviaStatus: false,
          modal: false,
        });
      } else {
        // Lógica para respuesta "No"
        this.$emit('changeValue', {
          triviaStatus: false,
          modal: true,
        });
      }
    },
  },
  mounted() {
    if (this.c13) {
      this.claseButton1 = 'btn btn-gradiente-naranjo btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-naranjo btn-auto btn-block';
    }else if (this.got){
      this.pregunta = "¿Quién es el conductor de Got Talent?";
      this.respuesta_1 = "Julián Elfenbein";
      this.respuesta_2 = "Julio Cesar Rodriguez";
      this.claseButton1 = 'btn btn-gradiente-gold btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-blue-cyan btn-auto btn-block';
    }else if (this.bunkers){
      this.pregunta = "Los Bunkers son una banda de la ciudad de:";
      this.respuesta_1 = "Concepción";
      this.respuesta_2 = "Los Angeles";
      this.claseButton1 = 'btn btn-gradiente-gold btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-blue-cyan btn-auto btn-block';
    }else if (this.mne){
      this.pregunta = "¿Quien es el animador de Mi Nombre Es?";
      this.respuesta_1 = "Jean Philippe ";
      this.respuesta_2 = "Luis Jara";
      this.claseButton1 = 'btn btn-gradiente-gold btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-blue-cyan btn-auto btn-block';
    }else if (this.festivallascondes){
      this.pregunta = "¿Quién conduce el Festival de Las Condes?";
      this.respuesta_1 = "Cristián Riquelme";
      this.respuesta_2 = "Francisco Saavedra";
      this.claseButton1 = 'btn btn-gradiente-gold btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-blue-cyan btn-auto btn-block';
    }else {
      this.claseButton1 = 'btn btn-gradiente-gold btn-auto btn-block';
      this.claseButton2 = 'btn btn-gradiente-blue-cyan btn-auto btn-block';
    }
  },
};
</script>
